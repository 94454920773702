import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch, message, Spin } from 'components/elements/CommonComponent';
import { Button, Checkbox } from 'components/elements/FormComponent';
import { addRequestCredit, getRequestCredit } from 'store/actions/billing/creditManagerAction';
import { Avatar, Modal, Popconfirm } from 'components/elements/AntComponent';
import BuyPlanModal from './modals/BuyPlanModal';
import { getUserProfile } from 'store/actions/authActions';
import { getCreditDetails } from 'store/actions/billing/creditManagerAction';

export default function ReportRate(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useNavigate();
    const { menu } = useSelector((state) => state.menu);
    const { loading5: usrPrfLoading, success5: proSuccess, error5: proError } = useSelector((state) => state.auth);
    const { getCredit, addReqCrd, success5, loading5, error5, getReqCrd, success6, loading6 } = useSelector((state) => state.creditManager);
    const serviceReqID = location?.state?.serviceReqID ? location?.state?.serviceReqID : props?.data?.saveOrder?.data?.servicerequestid;
    const accountId = location?.state?.accountId ? location?.state?.accountId : props?.data?.accountId;
    const [showsuccessmsg, setShowsuccessmsg] = useState(false);
    const successmsg = showsuccessmsg ? success5 : null;
    const [showErrmsg, setShowErrmsg] = useState(false);
    const errmsg = showErrmsg ? error5 : null;
    const [showsuccessmsg1, setShowsuccessmsg1] = useState(false);
    const successmsg1 = showsuccessmsg1 ? success6 : null;
    const [selectedItm, setSelectedItm] = useState([]);
    const [items1, setItems1] = useState([]);
    const [updateditm, setUpdatedItm] = useState([]);
    const billingType = props?.data?.billingType == 'credit' || menu?.billing_type == 'credit';
    const [totalCreditsUsed, setTotalCreditsUsed] = useState(0);
    const isUpdate = location?.state?.isUpdate;
    const { userProfileInfo } = useSelector((state) => state.auth);
    const [errormsg, setErrormsg] = useState('');
    const [validate, setValidate] = useState(0);
    const [selectedItems, setSelectedItems] = useState({
        NFB: props?.data?.isNFBChecked,
        MED: props?.data?.isMedChecked,
        RUSH: props?.data?.isRushChecked,
    });
    const [openModal1, setOpenModal1] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(false);
    const [availableCredit, setAvailableCredit] = useState(0);
    const [defaultCredit, setDefaultCredit] = useState(0);
    const userRole = sessionStorage.getItem('role');
    const [accountDetail, setAccountDetail] = useState({});
    const [isBalanceUpd, setIsBalanceUpd] = useState(false);
    const [showsuccessmsg2, setShowsuccessmsg2] = useState(false);
    const successmsg2 = showsuccessmsg2 ? proSuccess : null;
    const [showErrmsg2, setShowErrmsg2] = useState(false);
    const errmsg2 = showErrmsg2 ? proError : null;

    useEffect(() => {
        if (userProfileInfo?.data) {
            if (userProfileInfo?.data?.account_type === 'credit') {
                setValidate(userProfileInfo?.data?.balance_credit);
                if (Number(totalCreditsUsed) > Number(userProfileInfo?.data?.balance_credit)) {
                    setErrormsg(`Your available credit balance is ${validate}`);
                } else {
                    setErrormsg('');
                }
            }
        }
    }, [userProfileInfo]);

    useEffect(() => {
        if (props?.data?.availableCredit && userRole == 'admin') {
            setAvailableCredit(props?.data?.availableCredit);
        }
    }, [props?.data?.availableCredit]);

    useEffect(() => {
        if (props?.data?.accountDetail && userRole == 'admin') {
            setAccountDetail(props?.data?.accountDetail);
        }
    }, [props?.data?.accountDetail]);

    const getReqCredits = () => {
        dispatch(getRequestCredit(serviceReqID));
        setShowsuccessmsg1(true);
    };

    useEffect(() => {
        const updatedSelectedItm = [];
        Object.keys(selectedItems)?.map((creditCode) => {
            if (selectedItems[creditCode]) {
                const selectedItem = items1?.find((item) => item?.credit_code === creditCode);
                if (selectedItem) {
                    updatedSelectedItm.push(selectedItem?.id);
                }
            }
        });
        let arr = [];

        const selectedItem = items1?.filter((item) => item?.req_associate || item?.is_default || item?.is_associate);
        selectedItem?.forEach((item) => arr.push(item.id));
        updatedSelectedItm?.forEach((item) => arr.push(item));

        setSelectedItm(arr);

        const updatedItems = items1?.map((item) => {
            if (selectedItems[item?.credit_code]) {
                return { ...item, is_checked: true };
            } else {
                return { ...item, is_checked: false };
            }
        });
        setUpdatedItm(updatedItems);
    }, [selectedItems, items1]);

    const updateSelectedItems = () => {
        let newSelectedItems = { ...selectedItems };

        items1?.map((item) => {
            if (
                (item?.credit_code === 'NFB' && item?.req_associate == true && !item?.is_associate) ||
                (item?.credit_code === 'MED' && item?.req_associate == true && !item?.is_associate) ||
                (item?.credit_code === 'RUSH' && item?.req_associate == true && !item?.is_associate) ||
                (item?.credit_code === 'SUP' && item?.req_associate == true && !item?.is_associate) ||
                (item?.credit_code === 'IPR' && item?.req_associate == true && !item?.is_associate) ||
                (item?.credit_code === 'LFS' && item?.req_associate == true && !item?.is_associate) ||
                (item?.credit_code === 'RPT' && item?.req_associate == true && !item?.is_associate) ||
                (item?.credit_code === 'PBM' && item?.req_associate == true && !item?.is_associate) ||
                (item?.credit_code === 'IMG' && item?.req_associate == true && !item?.is_associate)
            ) {
                newSelectedItems[item.credit_code] = item.req_associate;
            }
        });
        const updatedItems = items1?.map((item) => {
            if (newSelectedItems[item?.credit_code]) {
                return { ...item, is_checked: true };
            } else {
                return { ...item, is_checked: false };
            }
        });
        setUpdatedItm(updatedItems);
        setSelectedItems(newSelectedItems);
    };

    useEffect(() => {
        if (isUpdate) {
            updateSelectedItems();
        }
    }, [isUpdate, items1]);

    // to calculate the total number of credit
    useEffect(() => {
        calculateTotalCreditsUsed();
    }, [updateditm]);

    const calculateTotalCreditsUsed = () => {
        let total = 0;
        updateditm?.forEach((item) => {
            if (item.is_checked || item.req_associate || item.is_default || item.is_associate) {
                total += item.credit_Value;
            }
        });
        setTotalCreditsUsed(total);
    };

    useEffect(() => {
        getReqCredits();
    }, [props?.data?.isSuccess == true, location?.state?.serviceReqID]);

    useEffect(() => {
        if (successmsg1) {
            setItems1(getReqCrd?.data);
            setShowsuccessmsg1(false);
        }
    }, [successmsg1]);

    useEffect(() => {
        if (successmsg) {
            setShowsuccessmsg(false);
            if (location?.state?.accountId && location?.state?.serviceReqID) {
                message.success('Report Item Updated Successfully');
                history('/view-request');
            } else {
                props?.data?.callbackReport(true, totalCreditsUsed);
            }
        }
        if (errmsg) {
            message.error(errmsg?.message);
            setShowErrmsg(false);
        }
    }, [successmsg, errmsg]);

    function addReqCredit() {
        let newArr = [...new Set(selectedItm)];
        let inputJson = {
            accountid: accountId,
            servicerequestid: serviceReqID,
            creditids: newArr,
        };
        dispatch(addRequestCredit(inputJson));
        setShowsuccessmsg(true);
        setShowErrmsg(true);
    }

    const handleRateChange = (item) => {
        const isChecked = !selectedItm?.includes(item?.id);
        if (isChecked) {
            setSelectedItm((prevItems) => [...prevItems, item?.id]);
            setTotalCreditsUsed((prevTotalCredits) => prevTotalCredits + item?.credit_Value);
            let total = Number(totalCreditsUsed) + Number(item?.credit_Value);
            if (Number(total) > Number(validate)) {
                setErrormsg(`Your available credit balance is ${validate}`);
            } else {
                setErrormsg('');
            }
        } else {
            setSelectedItm((prevItems) => prevItems?.filter((id) => id !== item?.id));
            setTotalCreditsUsed((prevTotalCredits) => prevTotalCredits - item?.credit_Value);
            let total = Number(totalCreditsUsed) - Number(item.credit_Value);
            if (Number(total) > Number(validate)) {
                setErrormsg(`Your available credit balance is ${validate}.`);
            } else {
                setErrormsg('');
            }
        }
    };

    const submitReport = () => {
        addReqCredit();
    };

    const getStatusIndicator = () => {
        if (Number(availableCredit) < Number(totalCreditsUsed)) {
            if (userRole == 'admin') {
                return (
                    <span className="indicator error-indicator">
                        {' '}
                        Insufficient credit. Available balance for {accountDetail?.label} is {availableCredit}. Charges will be applied upon submitting this request.{' '}
                    </span>
                );
            } else if (userRole == 'staff') {
                return <span className="indicator error-indicator">Insufficient credit. Available balance: {availableCredit}. Please buy more to proceed. </span>;
            }
        } else if (Number(availableCredit) === Number(totalCreditsUsed)) {
            if (userRole == 'admin') {
                return (
                    <span className="indicator warning-indicator">
                        {' '}
                        Warning: Available credit only covers the default report. Available balance for {accountDetail?.label} is {availableCredit}. Extra charges will be incurred for additional reports.
                    </span>
                );
            } else if (userRole == 'staff') {
                return <span className="indicator warning-indicator"> Warning: Available credit only covers the default report. Available balance: {availableCredit}. Additional items can't be saved.</span>;
            }
        }
        return null;
    };

    const closeModal = () => {
        setOpenModal1(false);
    };

    function getUser() {
        const inputJson = {
            userid: Number(sessionStorage.getItem('userid')),
        };
        dispatch(getUserProfile(inputJson));
        setShowsuccessmsg2(true);
        setShowErrmsg2(true);
    }

    const getDefaultCredit = () => {
        dispatch(getCreditDetails(accountId));
    };

    useEffect(() => {
        getDefaultCredit();
        if (userRole !== 'admin') {
            getUser();
        }
    }, [accountId]);

    useEffect(() => {
        const totalDefaultCreditValue = getCredit?.data?.filter((item) => item?.is_associate || item?.is_default)?.reduce((acc, item) => acc + item?.credit_Value, 0);

        setDefaultCredit(totalDefaultCreditValue);
    }, [getCredit]);

    useEffect(() => {
        if (userProfileInfo?.data && userRole != 'admin') {
            setAvailableCredit(userProfileInfo?.data?.total_credit - userProfileInfo?.data?.usedcredits);
        }
    }, [userProfileInfo]);

    const handleConfirm = () => {
        submitReport();
    };

    const showModals = () => {
        // history('/buy-credit');
        const fullUrl = `${window.location.origin}/buy-credit`;
        localStorage.setItem('order', true);
        window.open(fullUrl, '_blank');
        // setOpenModal1(true);
        setPaymentStatus(true);
    };

    useEffect(() => {
        if (paymentStatus == true) {
            const intervalId = setInterval(() => {
                const status = localStorage.getItem('paymentStatus');
                if (status == 'failed') {
                    setPaymentStatus(false);
                    Modal.error({
                        title: 'Payment Failed',
                        content: 'Payment was unsuccessful. Please try again.',
                        onOk() {
                            setTimeout(() => {
                                getUser();
                                getDefaultCredit();
                            }, 2000);
                            setIsBalanceUpd(true);
                        },
                    });
                    localStorage.removeItem('paymentStatus');
                } else if (status == 'succeed') {
                    setPaymentStatus(false);

                    Modal.success({
                        title: 'Payment Successful',
                        content: 'Payment was completed successfully.',
                        onOk() {
                            // localStorage.removeItem('paymentStatus');
                            setTimeout(() => {
                                getUser();
                                getDefaultCredit();
                            }, 2000);
                            setIsBalanceUpd(true);
                        },
                    });
                    localStorage.removeItem('paymentStatus');
                }
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [paymentStatus]);

    useEffect(() => {
        if (successmsg2) {
            setShowsuccessmsg2(false);
            setIsBalanceUpd(false);
        } else if (errmsg2) {
            setShowErrmsg2(false);
            setIsBalanceUpd(false);
        }
    }, [successmsg2, errmsg2]);

    const goBack = () => {
        history('/view-request');
    };

    return (
        <div className=" m-0 report_rate_style ">
            <div className="col text-start ps-0 my-auto pb-2 d-flex align-items-center">
                <h5 className="my-auto ms-0 pb-2"> Credits & Pricing </h5>
                <Button type="primary" className="ms-auto" onClick={goBack}>
                    Back
                </Button>
                {billingType && availableCredit <= totalCreditsUsed && (
                    <>
                        <div className="mx-auto">{getStatusIndicator()}</div>
                        {/* <div className="ms-auto d-flex col-auto mb-2">
                            <Button onClick={showModals} type="default">
                                Buy Plan
                            </Button>
                        </div> */}
                    </>
                )}
            </div>
            <div className="row m-0 px-0">
                <Spin spinning={usrPrfLoading || isBalanceUpd} className="px-0" style={{ padding: '0px' }}>
                    <div className="d-flex px-0">
                        <div className="Report-rate-table p-3 bg-white col ">
                            <div className="  my-3">
                                <span className="message">The report items which will be added in the request's report: </span>
                            </div>

                            <div className="text-center message d-flex mb-2 ms-0 ">
                                <div className="pt-1">
                                    <span className="message">
                                        <strong>Default report items: </strong>
                                    </span>
                                </div>
                            </div>

                            <table className="w-100 mb-4 ">
                                <thead>
                                    <tr>
                                        <th className="p-2 ps-3 ">Item</th>
                                        <th className="p-2 ps-3">Description</th>
                                        {billingType && <th className="p-2 ps-3 text-center">Credit Value</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading6 ? (
                                        updateditm && updateditm?.filter((item) => (item?.is_default || item?.is_associate) && item?.credit_code !== 'RPT').length > 0 ? (
                                            updateditm
                                                ?.filter((item) => (item?.is_default || item?.is_associate) && item?.credit_code !== 'RPT')
                                                ?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td className="p-2 ps-3" style={{ width: '25%' }}>
                                                            {item?.credit_item}
                                                        </td>
                                                        <td className="p-2 ps-3" style={{ width: billingType ? '60%' : '100%' }}>
                                                            {item?.credit_Descrisption ? item?.credit_Descrisption : '-'}
                                                        </td>
                                                        {billingType && (
                                                            <td className="p-2 ps-3 text-center " style={{ width: '15%' }}>
                                                                {item?.credit_Value}
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan={billingType ? '3' : '2'} className="text-center p-3">
                                                    No default report item has been selected.
                                                </td>
                                            </tr>
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan={billingType ? '3' : '2'} className="text-center">
                                                <Spin className="h-100 d-flex justify-content-center align-items-center p-3" />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <div className="text-center message d-flex mb-2 ms-0 ">
                                <div className="pt-1">
                                    <span className="message">
                                        <strong>Additional report items: </strong>
                                    </span>
                                </div>
                            </div>

                            <table className="w-100 mb-4">
                                <thead>
                                    <tr>
                                        <th className="p-2 ps-3 ">Item</th>
                                        <th className="p-2 ps-3">Description</th>
                                        {billingType && <th className="p-2 ps-3 text-center">Credit Value</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading6 ? (
                                        updateditm && updateditm?.filter((item) => !item?.is_default && !item?.is_associate).length > 0 ? (
                                            updateditm
                                                ?.filter((item) => !item?.is_default && !item?.is_associate)
                                                ?.map((item, i) => (
                                                    <tr key={i}>
                                                        <td className="p-2 ps-3 " style={{ width: '25%' }}>
                                                            <Checkbox onChange={() => handleRateChange(item)} defaultChecked={item?.is_checked} disabled={isUpdate ? item?.is_checked : false}>
                                                                {item?.credit_item}
                                                            </Checkbox>
                                                        </td>
                                                        <td className="p-2 ps-3 " style={{ width: billingType ? '60%' : '100%' }}>
                                                            {item?.credit_Descrisption ? item?.credit_Descrisption : '-'}
                                                        </td>
                                                        {billingType && (
                                                            <td className="p-2 ps-3 text-center " style={{ width: '15%' }}>
                                                                {item?.credit_Value}
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))
                                        ) : (
                                            <tr>
                                                <td colSpan={billingType ? '3' : '2'} className="text-center p-3">
                                                    No report items found.
                                                </td>
                                            </tr>
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan={billingType ? '3' : '2'} className="text-center">
                                                <Spin className="h-100 d-flex justify-content-center align-items-center p-3" />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {billingType && (
                                <div className="mb-4">
                                    <table className="w-100">
                                        <tbody>
                                            <tr>
                                                <td colSpan="2" className=" pe-2 border-0  fw-bold text-end">
                                                    Total Credit Used:
                                                </td>
                                                <td className="p-2 ps-3 text-center" style={{ width: '15%' }}>
                                                    {totalCreditsUsed}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {errormsg && userRole == 'staff' ? (
                                        <div className="text-danger mt-2 fw-bold fs-15 text-end ">
                                            {errormsg}{' '}
                                            <a className="text-underline" onClick={() => showModals()}>
                                                Click here to buy plan
                                            </a>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            )}

                            <div className="row mx-0 my-3  w-100">
                                {userRole === 'admin' && availableCredit <= defaultCredit && billingType ? (
                                    <Popconfirm
                                        title={`Available balance for ${accountDetail?.label} is ${availableCredit}. Charges will be applied upon submission.`}
                                        description="Do you still want to proceed?"
                                        onConfirm={handleConfirm}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="primary" className="col-auto ms-auto" loading={loading5}>
                                            Submit
                                        </Button>
                                    </Popconfirm>
                                ) : (
                                    <Button type="primary" className="col-auto ms-auto" disabled={userRole == 'staff' && billingType && errormsg} loading={loading5} onClick={submitReport}>
                                        Submit
                                    </Button>
                                )}
                            </div>
                        </div>
                        {location?.state?.details && (
                            <div className="col-md-3 ms-2">
                                <div className="p-3 bg-white">
                                    <div className="ms-0 p-0">
                                        <div className="row bg-light m-0 h-100 pt-2 d-flex align-items-center justify-content-center imageCont">
                                            <Avatar size={120} />
                                            <div className="text-center mt-2 mb-2 pt-1 ">
                                                <h6 className="text-center text-capitalize information mt-2">{location?.state?.details?.patient_name}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" d-flex flex-column mt-3 p-1">
                                        <div className="col ">
                                            <label className=" mb-0 fw-bold">Request #</label>
                                            <p className="information">{location?.state?.details?.encoded_RequestNumber || location?.state?.details?.encoded_request_number || '---'}</p>
                                        </div>
                                        <div className="col ">
                                            <label className=" mb-0 fw-bold">Request Type</label>
                                            <p className="information">{location?.state?.details?.request_type || '---'} </p>
                                        </div>
                                        <div className="col">
                                            <label className=" mb-0 fw-bold"> Request Status</label>
                                            <p className="information">
                                                {location?.state?.details?.status === 'On Review' || location?.state?.details?.status === 'acknowledged'
                                                    ? 'Acknowledged'
                                                    : location?.state?.details?.status == 'Request Init' && location?.state?.details?.is_active == true
                                                      ? 'Submitted'
                                                      : location?.state?.details?.status == 'Request Init' && location?.state?.details?.is_active == false
                                                        ? 'Patient Submitted'
                                                        : location?.state?.details?.status}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Spin>
            </div>
            <BuyPlanModal data={{ openModal: openModal1, closeModal: closeModal }} />
        </div>
    );
}
