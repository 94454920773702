import { createSlice } from '@reduxjs/toolkit';
import { getOpenInvoice, getInvoiceApprove, proceedToInvPayment, updatePaymentDetail, getInvStatistic, saveBillingMethod, getBillingMethod, generateInvocie, getAwaitingInvoice, generateCustomInvoice, getInvoiceInfo,updateInvoicePrice, getInvoiceGraph, getTransactionDetails, saveCardDetails } from 'store/actions/billing/invoiceManagerAction';

const initialState = {
    //get open invoice details 
    loading: false,
    openInvoice: null,
    error: null,
    success: false,
    //get invoive approve
    loading1: false,
    invoiceApp: null,
    error1: null,
    success1: false,
    // proceed to invoice payment 
    loading2: false,
    invoicePayment: null,
    error2: null,
    success2: false,
    // update payment detail
    loading3: false,
    updPayment: null,
    error3: null,
    success3: false,
    //get invoice statistic
    loading4: false,
    getStatistic: null,
    error4: null,
    success4: false,
    // save billing method
    loading5: false,
    getBillMtdInfo: null,
    error5: null,
    success5: false,
    //get billing method
    loading6: false,
    getBillMtd: null,
    error6: null,
    success6: false,
    //generate invoice
    loading7: false,
    generateInv: null,
    error7: null,
    success7: false,
    //generate invoice
    loading8: false,
    awaitInvData: null,
    error8: null,
    success8: false,
    // generate custom invoice 
    loading9: false,
    customInvoice: null,
    error9: null,
    success9: false,
    // invoice info 
    loading10: false,
    invoiceInfo: null,
    error10: null,
    success10: false,
    // update invoice price
    loading11: false,
    updatePrice: null,
    error11: null,
    success11: false,
    //get invoice graph
    loading12:false,
    invgraphInfo:null,
    error12:null,
    success12:false,
    //transaction history
    loading13:false,
    error13:null,
    transInfo:null,
    success13:false,
    //save card details 
    loading14:false,
    error14:null,
    cardInfo:null,
    success14:false,
}

const invoiceManagerSlice = createSlice({
    name: 'invoiceManager',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //get open invoice details
            .addCase(getOpenInvoice.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(getOpenInvoice.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.openInvoice = payload;
                state.success = true;
            })
            .addCase(getOpenInvoice.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            //get invoive approve
            .addCase(getInvoiceApprove.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(getInvoiceApprove.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.invoiceApp = payload;
                state.success1 = true;
            })
            .addCase(getInvoiceApprove.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            })
            // proceed to invoice payment
            .addCase(proceedToInvPayment.pending, (state) => {
                state.loading2 = true;
                state.error2 = null;
                state.success2 = false;
            })
            .addCase(proceedToInvPayment.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.invoicePayment = payload;
                state.success2 = true;
            })
            .addCase(proceedToInvPayment.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })
            //update payment detail 
            .addCase(updatePaymentDetail.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(updatePaymentDetail.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.updPayment = payload;
                state.success3 = true;
            })
            .addCase(updatePaymentDetail.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            //get invoice statisitc
            .addCase(getInvStatistic.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(getInvStatistic.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.getStatistic = payload;
                state.success4 = true;
            })
            .addCase(getInvStatistic.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })
            // save billing method
            .addCase(saveBillingMethod.pending, (state) => {
                state.loading5 = true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(saveBillingMethod.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.getBillMtdInfo = payload;
                state.success5 = true;
            })
            .addCase(saveBillingMethod.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.error5 = payload;
                state.success5 = false;
            })
            // get billing method
            .addCase(getBillingMethod.pending, (state) => {
                state.loading6 = true;
                state.error6 = null;
                state.success6 = false;
            })
            .addCase(getBillingMethod.fulfilled, (state, { payload }) => {
                state.loading6 = false;
                state.getBillMtd = payload;
                state.success6 = true;
            })
            .addCase(getBillingMethod.rejected, (state, { payload }) => {
                state.loading6 = false;
                state.error6 = payload;
                state.success6 = false;
            })
            // generate invoice 
            .addCase(generateInvocie.pending, (state) => {
                state.loading7 = true;
                state.error7 = null;
                state.success7 = false;
            })
            .addCase(generateInvocie.fulfilled, (state, { payload }) => {
                state.loading7 = false;
                state.generateInv = payload;
                state.success7 = true;
            })
            .addCase(generateInvocie.rejected, (state, { payload }) => {
                state.loading7 = false;
                state.error7 = payload;
                state.success7 = false;
            })
            //invoice 
            .addCase(getAwaitingInvoice.pending, (state) => {
                state.loading8 = true;
                state.error8 = null;
                state.success8 = false;
            })
            .addCase(getAwaitingInvoice.fulfilled, (state, { payload }) => {
                state.loading8 = false;
                state.awaitInvData = payload;
                state.success8 = true;
            })
            .addCase(getAwaitingInvoice.rejected, (state, { payload }) => {
                state.loading8 = false;
                state.error8 = payload;
                state.success8 = false;
            })
             // genreate cusotm invoice
             .addCase(generateCustomInvoice.pending, (state) => {
                state.loading9 = true;
                state.error9 = null;
                state.success9 = false;
            })
            .addCase(generateCustomInvoice.fulfilled, (state, { payload }) => {
                state.loading9 = false;
                state.customInvoice = payload;
                state.success9 = true;
            })
            .addCase(generateCustomInvoice.rejected, (state, { payload }) => {
                state.loading9 = false;
                state.error9 = payload;
                state.success9 = false;
            })
            // get invoice info 
            .addCase(getInvoiceInfo.pending, (state) => {
                state.loading10 = true;
                state.error10 = null;
                state.success10 = false;
            })
            .addCase(getInvoiceInfo.fulfilled, (state, { payload }) => {
                state.loading10 = false;
                state.invoiceInfo = payload;
                state.success10 = true;
            })
            .addCase(getInvoiceInfo.rejected, (state, { payload }) => {
                state.loading10 = false;
                state.error10 = payload;
                state.success10 = false;
            })
            // update invoice price 
            .addCase(updateInvoicePrice.pending, (state) => {
                state.loading11 = true;
                state.error11 = null;
                state.success11 = false;
            })
            .addCase(updateInvoicePrice.fulfilled, (state, { payload }) => {
                state.loading11 = false;
                state.updatePrice = payload;
                state.success11 = true;
            })
            .addCase(updateInvoicePrice.rejected, (state, { payload }) => {
                state.loading11 = false;
                state.error11 = payload;
                state.success11 = false;
            })
             // get invoice graph
             .addCase(getInvoiceGraph.pending, (state) => {
                state.loading12 = true;
                state.error12 = null;
                state.success12 = false;
                state.invgraphInfo=null;
            })
            .addCase(getInvoiceGraph.fulfilled, (state, { payload }) => {
                state.loading12 = false;
                state.invgraphInfo = payload;
                state.success12 = true;
            })
            .addCase(getInvoiceGraph.rejected, (state, { payload }) => {
                state.loading12 = false;
                state.error12 = payload;
                state.success12 = false;
            })
            .addCase(getTransactionDetails.pending, (state) => {
                state.loading13 = true;
                state.error13 = null;
                state.success13 = false;
                state.transInfo=null;
            })
            .addCase(getTransactionDetails.fulfilled, (state, { payload }) => {
                state.loading13 = false;
                state.transInfo = payload;
                state.success13 = true;
            })
            .addCase(getTransactionDetails.rejected, (state, { payload }) => {
                state.loading13 = false;
                state.error13 = payload;
                state.success13 = false;
            })
            // save card details 
            .addCase(saveCardDetails.pending, (state) => {
                state.loading14 = true;
                state.error14 = null;
                state.success14 = false;
                state.transInfo=null;
            })
            .addCase(saveCardDetails.fulfilled, (state, { payload }) => {
                state.loading14 = false;
                state.cardInfo = payload;
                state.success14 = true;
            })
            .addCase(saveCardDetails.rejected, (state, { payload }) => {
                state.loading14 = false;
                state.error14 = payload;
                state.success14 = false;
            })
    },
})
export default invoiceManagerSlice.reducer