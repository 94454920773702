import { createSlice } from '@reduxjs/toolkit';
import { addAmplifier, amplifierMaster } from 'store/actions/master-data/AmpliferActions';



    const initialState = {
    // get amplifier master 
    loading: false,
    amplifierInfo: null,
    error: null,
    success: false,

    // save amplifier
    loading1: false,
    addAmplifierInfo: null, 
    error1:null,
    success1: false,

    }

    const amplifierMasterSlice = createSlice({
    name: 'amplifierMaster',
    initialState,
    reducers: {},
    extraReducers: (builder) =>{
        builder 
        // get amplifeir 
        .addCase(amplifierMaster.pending,(state)=>{
            state.loading = true;
            state.error = null;
            state.success = false;
        })
          .addCase(amplifierMaster.fulfilled,(state,{payload})=>{
            state.loading = false;
            state.amplifierInfo = payload;
            state.success = true;
        })
          .addCase(amplifierMaster.rejected,(state,{payload})=>{
            state.loading = false;
            state.error = payload;
            state.success=false;
        })

        // save amplifier
        .addCase(addAmplifier.pending,(state)=>{
            state.loading1 = true;
            state.error1 = null;
            state.success1 = false;
        })
          .addCase(addAmplifier.fulfilled,(state,{payload})=>{
            state.loading1 = false;
            state.addAmplifierInfo = payload;
            state.success1 = true;
        })
          .addCase(addAmplifier.rejected,(state,{payload})=>{
            state.loading1 = false;
            state.error1 = payload;
            state.success1=false;
        })
          

    }

})

export default  amplifierMasterSlice.reducer