import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/elements/FormComponent';
import { Result, useSelector } from 'components/elements/CommonComponent';

export default function AutopayError() {
    const navigate = useNavigate();
    const { accUpdateInfo } = useSelector((state) => state.account);
    
    return (
        <div className=" vh-100 bg-white">
            <div className="p-5">
                <Result
                    className="p-5"
                    status="error"
                    title={`Payment Detail Save Failed`}
                    subTitle="There was an issue saving the payment details, Try again later"
                    extra={[
                        <Button type="primary" className="mx-auto text-center" key="console"  onClick={() =>
                            navigate('/account/account-details', {
                                state: {
                                    uid: accUpdateInfo?.data?.Acc_GUID || '',
                                    accountID: accUpdateInfo?.data?.id,
                                    activeFrom: accUpdateInfo?.data?.created_on,
                                },
                            })
                        }>
                            Back to User Management
                        </Button>,
                    ]}
                />
            </div>
        </div>
    );
}
