import { createSlice } from '@reduxjs/toolkit';

const uploadSlice = createSlice({
  name: 'upload',
  initialState: {
    progress: 0,
    uploadReqProgress:0,
    uploadDocProgress:0,
  },
  reducers: {
    updateProgress: (state, action) => {
      state.progress = action.payload;
    },
    uploadRequestProgress: (state, action) => {
      state.uploadReqProgress = action.payload;
    },
    docUploadProgress: (state,action) => {
      state.uploadDocProgress = action.payload;
    },
    resetUploadProgress: (state) => {
      state.progress = 0; // Reset upload progress to 0%
  },
  },
});

export const { updateProgress,uploadRequestProgress,docUploadProgress, resetUploadProgress } = uploadSlice.actions;
export default uploadSlice.reducer;