import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Divider } from 'components/elements/AntComponent';
import { Input, Form, Select, Button, Radio } from 'components/elements/FormComponent';
import { useDispatch, useSelector } from 'components/elements/CommonComponent';
import { addUser, getUserGroup } from 'store/actions/account/accountActions';
import { LoadingOutlined } from 'components/elements/IconComponent';
import { AccountTypes } from 'store/actions/service-request/newRequestActions';

const { Option } = Select;

export default function AddNewUser(props) {
    const defaultValues = props.data.rowData;
    const location = useLocation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [setActive, setIsActive] = useState(true);
    const [open, setOpen] = useState(props.data.open);
    const { loading4, success4, success5, getUserGrp, loading5 } = useSelector((state) => state.account);
    const { accType, loading2 } = useSelector((state) => state.request);
    const passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const [showmsg, setShowmsg] = useState(false);
    const [role, setRole] = useState('Account');
    const successmsg = showmsg ? success4 : null;
    const [showmsg1, setShowmsg1] = useState(false);
    const successmsg1 = showmsg1 ? success5 : null;
    const [userRole, setUserRole] = useState();
    const [roleOption, setRoleOption] = useState();
    const [roleOption1, setRoleOption1] = useState();
    const [selectedRoles, setSelectedRoles] = useState('');
    const accTypes = accType?.data || [];
    const isAccount = props?.data?.isAccount;
    const url = window.origin;

    const handleOk = async () => {
        let value = await form.validateFields();
        try {
            let inputJson = {
                userid: 0,
                username: value.email,
                email: value.email,
                first_name: value.firstname,
                last_name: value.lastname,
                password: 'x#gGj2ksls',
                password2: 'x#gGj2ksls',
                domain: url || '',
                accountid: isAccount ? value.account || 0 : location?.state?.accountID || 0,
                groups: role === 'Account' ? (value.userrole === '0' ? ['Accounts'] : ['Accounts', value.userrole]) : value.userrole === '1000' ? [] : [value.userrole],
                // mfa_enabled: value?.mfa,
            };
            dispatch(addUser(inputJson));
            setShowmsg(true);
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

    useEffect(() => {
        dispatch(AccountTypes());
    }, [dispatch, isAccount]);

    useEffect(() => {
        if (defaultValues) {
            setIsActive(defaultValues?.is_active);
            setRole(defaultValues?.is_superuser ? 'Admin' : 'Account');
        }
    }, [defaultValues]);

    const handleUpdateOk = async () => {
        try {
            let value = await form.validateFields();
            let inputJson = {
                userid: defaultValues !== null ? defaultValues.id : 0,
                username: value.username,
                email: value.email,
                first_name: value.firstname,
                last_name: value.lastname,
                is_active: setActive,
                accountid: isAccount ? Number(value.account) || 0 : location?.state?.accountID || 0,
                groups: role === 'Account' ? (value.userrole === '0' ? ['Accounts'] : ['Accounts', value.userrole]) : value.userrole === '1000' ? [] : [value.userrole],
                // mfa_enabled: value?.mfa,
            };
            dispatch(addUser(inputJson));
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

    const getUserGroupDetail = () => {
        dispatch(getUserGroup());
        setShowmsg1(true);
    };

    useEffect(() => {
        getUserGroupDetail();
    }, []);

    useEffect(() => {
        if (successmsg1) {
            setUserRole(getUserGrp?.data);
        }
    }, [successmsg1]);

    useEffect(() => {
        let arr = [],
            arr1 = [];
        arr.push({ label: 'Account Admin', value: '0' });
        arr1.push({ label: 'Admin', value: '1000' });

        // if (userRole) {
        for (let i = 0; i < userRole?.length; i++) {
            if (userRole[i]?.name.toLowerCase() !== 'accounts') {
                arr.push({ label: userRole[i]?.name, value: userRole[i]?.name });
            }
        }
        // }
        // if (usrRole === 'staff') {
        arr1 = arr.map((item) => {
            if (item.value === 'billing') {
                return { ...item, label: 'Lab Billing' };
            }
            if (item.value === 'technician') {
                return { ...item, label: 'Lab Technician' };
            }
            return item;
        });
        // }
        const filtArr1 = (arr || []).filter((item) => item.value !== '0');
        filtArr1.push({ label: 'Admin', value: '1000' });
        setRoleOption(filtArr1);
        const filtArr = (arr1 || []).filter((item) => item.value !== 'researcher');
        setRoleOption1(filtArr);
    }, [userRole]);

    const handleRoleChange = (value) => {
        if (value === '0') {
            setSelectedRoles('Accounts');
            // form.setFieldsValue({ userrole: 'Accounts' });
        } else if (value === '1000') {
            setSelectedRoles('');
            // form.setFieldsValue({ userrole: '' });
        } else {
            setSelectedRoles(value);
        }
    };

    useEffect(() => {
        if (defaultValues && defaultValues.groups?.[0]) {
            const defaultGroup =
                defaultValues?.groups?.length === 0 ? '1000' : defaultValues?.groups?.length === 1 ? (defaultValues?.groups[0]?.toLowerCase() === 'accounts' ? '0' : defaultValues?.groups[0]) : defaultValues?.groups[1];
            if (defaultGroup === 'Accounts') {
                form.setFieldValue('userrole', 'Account Admin');
            } else {
                form.setFieldValue('userrole', defaultGroup);
            }
            form.setFieldValue('account', defaultValues?.accountid?.toString());
        }
    }, [defaultValues, roleOption]);

    useEffect(() => {
        if (successmsg) {
            setShowmsg(false);
            handleCancel();
        }
    }, [successmsg]);

    const handleCancel = () => {
        setOpen(false);
        props.data.showModal(false);
    };
    const validateConfirmPassword = ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
    });

    const initialValues = {
        email: defaultValues !== null ? defaultValues.email : '',
        username: defaultValues !== null ? defaultValues.username : '',
        firstname: defaultValues !== null ? defaultValues.first_name : '',
        lastname: defaultValues !== null ? defaultValues.last_name : '',
        password: '',
        confirmpassword: '',
        mfa: defaultValues !== null ? defaultValues?.mfa_enabled : false,
    };

    const accountOptions = accTypes.map((item) => {
        return { value: item.id?.toString(), label: item.acct_name };
    });

    const userOption = [
        { label: 'Account', value: 'Account' },
        { label: 'Admin', value: 'Admin' },
    ];

    return (
        <Modal
            title={defaultValues !== null ? 'Update  User' : 'Create User'}
            open={open}
            // onOk={defaultValues !== null ? handleUpdateOk : handleOk}
            onCancel={handleCancel}
            width={500}
            maskClosable={false}
            // okText={defaultValues !== null ? "Update" : "Save"}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading4} onClick={defaultValues !== null ? handleUpdateOk : handleOk}>
                    {defaultValues !== null ? 'Update' : 'Save'}
                </Button>,
            ]}
        >
            <Divider className="header-divider" />
            <Form autoComplete="off" form={form} initialValues={initialValues} layout="vertical">
                <div className=" text-start">
                    <div>
                        <Form.Item
                            label="Email"
                            name="email"
                            className="w-100"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Enter valid E-mail!',
                                },
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </div>
                    {/* <div className="col ">
                            <Form.Item
                                key={Math.random()}
                                label="User Name" name='username' className='w-100' rules={[
                                    {
                                        required: true,
                                        message: 'User name is required',
                                    },
                                ]}
                            >
                                <Input autoComplete="new-password" />
                            </Form.Item>
                        </div> */}
                </div>

                <div className=" text-start">
                    <div>
                        <Form.Item
                            label="First Name"
                            name="firstname"
                            className="w-100"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div>
                        <Form.Item
                            label="Last Name"
                            name="lastname"
                            className="w-100"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    {isAccount ? (
                        <Form.Item label="User type" className="w-100" rules={[{ required: true }]}>
                            <Radio.Group
                                block
                                options={userOption}
                                optionType="button"
                                buttonStyle="solid"
                                defaultValue={role}
                                value={role}
                                onChange={(e) => {
                                    setRole(e.target.value);
                                    form.setFieldsValue({ userrole: '', account: '' });
                                }}
                            />
                        </Form.Item>
                    ) : (
                        ''
                    )}
                    <div>
                        <Form.Item
                            label="User Role"
                            name="userrole"
                            className="w-100"
                            rules={[
                                {
                                    required: true,
                                    message: 'This field is required',
                                },
                            ]}
                        >
                            <Select
                                className="w-100"
                                notFoundContent={
                                    <div className="text-center p-4">
                                        {loading5 ? (
                                            <span>
                                                <LoadingOutlined />
                                                Loading...
                                            </span>
                                        ) : (
                                            <span>No role available</span>
                                        )}
                                    </div>
                                }
                                onChange={handleRoleChange}
                                options={role === 'Admin' ? roleOption : roleOption1}
                                // default={selectedRoles}
                            ></Select>
                        </Form.Item>
                    </div>
                    {role === 'Account' && isAccount && (
                        <div>
                            <Form.Item label="Account" name="account" className="col w-100" rules={[{ required: role === 'Account' ? true : false, message: 'This field is required' }]}>
                                <Select
                                    showSearch
                                    placeholder=""
                                    optionFilterProp="children"
                                    // disabled={defaultValues !== null ? true : false}
                                    filterOption={(input, option) => {
                                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                    filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                    notFoundContent={
                                        <div className="text-center p-4">
                                            {loading2 ? (
                                                <span>
                                                    <LoadingOutlined />
                                                    Loading...
                                                </span>
                                            ) : (
                                                <span>No account available</span>
                                            )}
                                        </div>
                                    }
                                    options={accountOptions}
                                    // onChange={handleAccountChange}
                                />
                            </Form.Item>
                        </div>
                    )}
                </div>
                {/* <div>
                    <Form.Item label="Is MFA Enabled" name="mfa" className="col w-100">
                        <Radio.Group name="radiogroup">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div> */}

                {defaultValues !== null ? (
                    <div className=" text-start">
                        <div>
                            <Form.Item label="Status" name="status" className="w-100 p-0">
                                <div className="w-100 mb-2 p-0 active-btn-antd" onClick={() => setIsActive(!setActive)}>
                                    {setActive ? (
                                        <Button className="mt-0 w-100 active-button" value="Active">
                                            Active
                                        </Button>
                                    ) : (
                                        <Button className="mt-0 w-100 inactive-button" value="InActive">
                                            InActive
                                        </Button>
                                    )}
                                </div>
                            </Form.Item>
                        </div>
                        {/* <div>
                            <Form.Item />
                        </div> */}
                    </div>
                ) : (
                    ''
                    // <div className=" text-start">
                    //     <div>
                    //         <Form.Item
                    //             label="Password"
                    //             name="password"
                    //             className="w-100"
                    //             rules={[
                    //                 {
                    //                     required: true,
                    //                     message: 'Password is required',
                    //                 },
                    //                 {
                    //                     pattern: passwordPattern,
                    //                     message: 'Password must contain at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character',
                    //                 },
                    //             ]}
                    //         >
                    //             <Input.Password autoComplete="new-password" />
                    //         </Form.Item>
                    //     </div>
                    //     <div>
                    //         <Form.Item
                    //             validateTrigger="onChange"
                    //             label="Confirm Password"
                    //             name="confirmpassword"
                    //             className="w-100"
                    //             rules={[
                    //                 {
                    //                     required: true,
                    //                     message: 'Confirm password is required',
                    //                 },
                    //                 validateConfirmPassword,
                    //             ]}
                    //         >
                    //             <Input.Password />
                    //         </Form.Item>
                    //     </div>
                    // </div>
                )}
            </Form>
        </Modal>
    );
}
