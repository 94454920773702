import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';

const backendURL = config.apiURL;

export const userLogin = createAsyncThunk('auth/login', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}auth/login`, payload);
        // store user's token in local storage
        localStorage.setItem('token', true);
        localStorage.setItem('userid', data.data.userid);
        sessionStorage.setItem('userid', data.data.userid);
        sessionStorage.setItem('role', data.data.role);
        sessionStorage.setItem('accountid', data.data.user_acctid);
        sessionStorage.setItem('firstname', data.data.firstname);
        sessionStorage.setItem('lastname', data.data.lastname);
        sessionStorage.setItem('username', data.data.username);
        sessionStorage.setItem('password', data.data.password);
        // if (!data?.data?.mfa_enabled) {
        //     localStorage.setItem('token', true);
        // }
        if (data.data.role == 'staff') {
            if (data.data.groups?.length == 1) {
                sessionStorage.setItem('isAdmin', 'Admin');
            } else {
                sessionStorage.setItem('isAdmin', data.data.groups?.[1]);
            }
        } else {
            if (data.data?.groups?.[0]) {
                sessionStorage.setItem('isAdmin', data.data?.groups?.[0]);
            } else {
                sessionStorage.setItem('isAdmin', '');
            }
        }

        // localStorage.setItem("username",data.data.username);
        // localStorage.setItem("password",data.data.password);
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//auth/password_reset
export const SendEmail = createAsyncThunk('auth/send-email', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}auth/password_reset`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//change password
export const ChangePassword = createAsyncThunk('auth/changepass', async (payload, { rejectWithValue }) => {
    try {
        // configure header's Content-Type as JSON
        const { data } = await ax.put(`${backendURL}auth/change-password`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//confirm reset password
export const confirmPass = createAsyncThunk('auth/confirm-pass', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}auth/password_reset/confirm/`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//verify token
export const VerifyToken = createAsyncThunk('auth/verify-token', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}auth/reset-password/verify-token`, payload);
        // store user's token in local storage
        return data;
    } catch (error) {
        // return custom error message from API if any
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

export const getUserProfile = createAsyncThunk('account-user-profile', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}account/UserProfileCall`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

// auth/login_auth
export const changeDefaultPass = createAsyncThunk('default-pass-user-profile', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}auth/login_auth`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

// logout api
export const userLogout = createAsyncThunk('logout', async () => {
    try {
        const { data } = await ax.get(`${backendURL}auth/logout`);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            // return rejectWithValue(error.response.data)
        } else {
            // return rejectWithValue(error.message)
        }
    }
});

// mfa/verify
export const mfaCodeVerify = createAsyncThunk('mfa-verify', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}auth/mfa_verify`, payload);
        // if (data?.status === 'success') {
        //     localStorage.setItem('token', true);
        // }
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});

//mfa/rest
export const mfaCodeReset = createAsyncThunk('mfa-reset', async (payload, { rejectWithValue }) => {
    try {
        const { data } = await ax.post(`${backendURL}auth/mfa_reset`, payload);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
            return rejectWithValue(error.response.data);
        }
    }
});
