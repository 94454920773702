import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/elements/FormComponent';
import { Result, useSelector } from 'components/elements/CommonComponent';

export default function AutopaySuccess() {
    const navigate = useNavigate();
    const { accUpdateInfo } = useSelector((state) => state.account);

    return (
        <div className=" vh-100 bg-white">
            <div className="p-5">
                <Result
                    className="p-5"
                    status="success"
                    title={`Payment Detail Saved Successful`}
                    subTitle="Thank you"
                    extra={[
                        <Button
                            type="primary"
                            className="mx-auto text-center"
                            key="console"
                            onClick={() =>
                                navigate('/account/account-details', {
                                    state: {
                                        uid: accUpdateInfo?.data?.Acc_GUID || '',
                                        accountID: accUpdateInfo?.data?.id,
                                        activeFrom: accUpdateInfo?.data?.created_on,
                                    },
                                })
                            }
                        >
                            Back to User Management
                        </Button>,
                    ]}
                />
            </div>
        </div>
    );
}
