import config from 'assets/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ax } from 'store/base';
import { updateProgress, resetUploadProgress } from 'store/slices/uploadFile';

const backendURL = config.apiURL;

export const getOrderManagement = createAsyncThunk(
    'ordermanagement-get',
    async (id) => {
        try {            
            const { data } = await ax.get(
                `${backendURL}service_request/getaccountrequest/${id}`
            )
            return data
        }catch (error) {
            if (error.response && error.response.data) {
                return error.response.data;
            } else {
                return error.response;
            }
        }
    }
);

let uploadprogress=0;
export const saveOrder = createAsyncThunk(
    'saveOrder',
    async(formData,{dispatch,rejectWithValue})=>{
        try{
            const {data} = await ax.post(
                `${backendURL}service_request/accountsaveServicerequest`,
                formData,
                { onUploadProgress: (progressEvent) => {
                    uploadprogress = Math.round(
                     (progressEvent.loaded / progressEvent.total) * 100
                   );
                   dispatch(updateProgress(uploadprogress));
                 }}
            )
            return data;
        }
        catch(error){
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);


export const requestMedication=createAsyncThunk(
    'request-medications',
    async(payload,{rejectWithValue})=>{
        try{
            const {data} = await ax.post(
                `${backendURL}patient/requestmedication`,
                payload
            )
            return data;
        }  
        catch(error){
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);

export const adminSaveOrder = createAsyncThunk(
    'adminSaveOrder',
    async(formData,{dispatch,rejectWithValue})=>{
        try{
            dispatch(updateProgress(0));
            let progress = 0;
            const {data} = await ax.post(
                `${backendURL}service_request/adminsaveServicerequest`,
                formData,
                { onUploadProgress: (progressEvent) => {
                    progress = Math.round(
                     (progressEvent.loaded / progressEvent.total) * 100
                   );
                   dispatch(updateProgress(progress));
                 }}
            )
            return data;
        }
        catch(error){
            if (error.response && error.response.data) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);
