import React, { useState, useEffect } from 'react';
import { Divider, Row, Col, Card } from 'components/elements/AntComponent';
import { CheckCircleOutlined } from 'components/elements/IconComponent';
import { Button, Radio } from 'components/elements/FormComponent';
import { useSelector, useDispatch } from 'components/elements/CommonComponent';
import { getBillPayment, proceedToPayment } from 'store/actions/billing/creditManagerAction';
import { useNavigate } from 'react-router-dom';

export default function PaymentDetails(props) {
    const accountId = sessionStorage.getItem('accountid');
    const dispatch = useDispatch();
    const history = useNavigate();
    const handleNavigateBack = () => {
        props.data.drawerCallbackFunc(false);
    };
    const selectedPackage = props.data.selectedPackage;
    const planDetail = props.data.planDetail?.data[selectedPackage - 1];
    const { getBillPay, loading12, success12, loading13, success13, proceedPay } = useSelector((state) => state.creditManager);
    const paymentDetail = getBillPay?.data;
    const [showSuccessmgs, setShowSuccessmsgs] = useState(false);
    const successmsgs = showSuccessmgs ? success13 : false;
    const creditAmount = parseFloat(paymentDetail?.actual_amount);
    const platformFee = parseFloat(creditAmount * (2.9642105263158 / 100));
    const totalAmount = creditAmount + platformFee;
    const [paymentVia, setPaymentVia] = useState('card');
    const roundToTwoDecimalPlaces = (num) => {
        return Math.round(num * 100) / 100;
    };
    const ccfee = roundToTwoDecimalPlaces(parseFloat((2.9 / 100) * parseFloat(paymentDetail?.actual_amount || 0) + 0.3));

    const handlePayChange = (e) => {
        setPaymentVia(e.target.value);
    };

    useEffect(() => {
        let ids = `${accountId}/${selectedPackage}`;
        dispatch(getBillPayment(ids));
    }, [selectedPackage]);

    const handlePayment = () => {
        const protocol = window.location.protocol;
        const port = window.location.port || (window.location.protocol === 'https:' ? '443' : '80');
        const success = `${protocol}//${window.location.hostname}${port ? ':' + port : ''}/success-payment`;
        const error = `${protocol}//${window.location.hostname}${port ? ':' + port : ''}/error-payment`;
        let inputJson = {
            payment_method: "stripe",
            accountid: Number(accountId),
            credit_packageid: Number(planDetail?.id),
            // amount: paymentVia === 'credit' ? Number(paymentDetail?.actual_amount) + parseFloat(ccfee) : Number(paymentDetail?.actual_amount),
            amount: Number(paymentDetail?.actual_amount),
            fee: Number(0),
            payment_method_type: paymentVia || '',
            processing_fee: paymentVia === 'card' ? ccfee : '0.00',
            success_url: success,
            cancel_url: error,
        };
        localStorage.setItem('paymentStatus', 'pending');
        dispatch(proceedToPayment(inputJson));
        setShowSuccessmsgs(true);
    };

    useEffect(() => {
        if (successmsgs) {
            localStorage.setItem('paymentId', proceedPay?.id);
            if (!props.data.edit) {
                window.location.href = proceedPay?.url;
                setShowSuccessmsgs(false);
            } else {
                const popupWidth = 800;
                const popupHeight = 600;
                const left = window.innerWidth / 2 - popupWidth / 2;
                const top = window.innerHeight / 2 - popupHeight / 2;

                const popup = window.open(proceedPay?.url, 'PaymentPopup', `width=${popupWidth},height=${popupHeight},top=${top},left=${left},scrollbars=yes`);
                localStorage.setItem('paymentPopup', popup?.name);
                setShowSuccessmsgs(false);
                props?.data?.closeModal();
                props?.data?.resetBuyModal();
            }
        }
    }, [successmsgs]);

    return (
        <div className="paymentDet ">
            <div className="row me-1 pe-0 justify-content-between mb-2">
                <h5 className="ps-3 col">Payment Details</h5>
                <Button type="primary" className="ms-auto col-auto" onClick={handleNavigateBack}>
                    Back
                </Button>
            </div>
            <div className="paymentDet-cont rounded pt-1 mb-4">
                <div className="d-flex justify-content-between p-2">
                    <p className="ps-2 summary-title mb-2"> Plan Details</p>
                    {/* <Button type='primary' onClick={handleNavigateBack}>Back</Button> */}
                </div>
                <Divider className="planDivider" />
                <div className="ps-4 pt-3 part-1">
                    <Row gutter={24}>
                        <Col span={10}>
                            <Card bordered={false} className="planDetailCont bg-light">
                                <div className="ps-2">
                                    <div className="planName pb-1">
                                        <CheckCircleOutlined className="text-success " />
                                        <span className="ps-2 text-capitalize">{planDetail?.package_plan}</span>
                                    </div>
                                    <div>
                                        <p className="planDetail">
                                            ${planDetail?.package_price} USD <span>({planDetail?.credit_count} Credit)</span>
                                        </p>
                                    </div>
                                    <div>
                                        <Button type="primary" className="explore-btn" onClick={handleNavigateBack}>
                                            Explore Plan
                                        </Button>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Divider className="" />
                <div className="part-2 ps-4 pb-4">
                    <div className="mb-2">
                        <h6>Pay via</h6>
                        <Radio.Group defaultValue={paymentVia} onChange={handlePayChange}>
                            <Radio value="card">Credit Card</Radio>
                            <Radio value="us_bank_account">ACH</Radio>
                            <Radio value="cashapp">Cash App</Radio>
                        </Radio.Group>
                    </div>

                    <div className="col-md-5 pt-0 pb-0" style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr style={{ borderBottom: '1px solid #ccc' }}>
                                    <th style={{ paddingBottom: '10px', paddingTop: '10px', fontWeight: 'bold', width: '50%', borderRight: '1px solid #ccc' }}>Payment Description</th>
                                    <th style={{ paddingBottom: '10px', paddingTop: '10px', fontWeight: 'bold', textAlign: 'end', width: '50%' }}>Amount ($)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* <tr>
                                    <td style={{ paddingTop: '10px', borderRight: '1px solid #ccc' }}>Credit Used </td>
                                    <td style={{ textAlign: 'end', paddingTop: '5px', paddingBottom: '5px' }}>{paymentDetail?.credit_count}</td>
                                </tr> */}
                                <tr>
                                    <td className="ps-1" style={{ paddingTop: '10px', borderRight: '1px solid #ccc' }}>Amount ($)</td>
                                    <td className="pe-1" style={{ textAlign: 'end', paddingTop: '5px', paddingBottom: '5px' }}>{parseFloat(paymentDetail?.actual_amount || 0).toFixed(2)}</td>
                                </tr>
                                {/* <tr>
                            <td  style={{ paddingTop:'10px', paddingBottom:'5px',borderRight: '1px solid #ccc'}}>Platform Fee ($)</td>
                            <td style={{ textAlign: 'end', paddingTop:'5px', paddingBottom:'5px' }}>{parseFloat(platformFee).toFixed(2)}</td>
                        </tr> */}
                                    {paymentVia === 'card' ? (
                                        <tr>
                                            <td className="ps-1" style={{ paddingTop: '10px', paddingBottom: '5px', borderRight: '1px solid #ccc' }}>
                                                Processing Fees
                                            </td>
                                            <td className="pe-1" style={{ textAlign: 'end', paddingTop: '5px', paddingBottom: '5px' }}>
                                                {paymentVia === 'card' ? <>{parseFloat(ccfee).toFixed(2)}</> : '0.00'}
                                            </td>
                                        </tr>
                                    ) : (
                                        ''
                                        )}
                                    <tr>
                                    <td colSpan="" style={{ borderTop: '1px solid #f2f2f2', paddingTop: '10px', paddingBottom: '10px', fontWeight: 'bold', borderRight: '1px solid #ccc' }}>
                                        Total Amount ($)
                                    </td>
                                    <td colSpan="" style={{ borderTop: '1px solid #f2f2f2', paddingTop: '10px', paddingBottom: '10px', textAlign: 'end', fontWeight: 'bold' }}>
                                        {paymentVia === 'card' ? (
                                            <>{parseFloat(paymentDetail?.actual_amount + ccfee || 0).toFixed(2)}{' '}</>
                                        ):(
                                            <>{parseFloat(paymentDetail?.actual_amount || 0).toFixed(2)}{' '}</>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="d-flex justify-content-end mt-3 mb-3">
                        <Button type="primary" className="explore-btn me-2" loading={loading13} onClick={handlePayment}>
                            Proceed to Payment
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
