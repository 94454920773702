import { createSlice } from '@reduxjs/toolkit';
import { accountList, accountUser, addAccount, addUser, getAccountData, getUserGroup, getUserData } from 'store/actions/account/accountActions';

const initialState = {
    //account-list
    loading: false,
    accountManagementInfo: null,
    error: null,
    success: false,
    //add account
    loading1: false,
    addAccInfo: null,
    error1: null,
    success1: false,
    //get account user
    loading2: false,
    accUserInfo: null,
    error2: null,
    success2: false,
    //get account
    loading3: false,
    accUpdateInfo: null,
    error3: null,
    success3: false,
    //add user
    loading4: false,
    addUserInfo: null,
    error4: null,
    success4: false,
    // add user group
    loading5: false,
    getUserGrp: null,
    error5: null,
    success5: false,
    // get user data 
    loading6: false,
    getUserInfo: null,
    error6: null,
    success6: false,
};

const accountManagementSlice = createSlice({
    name: 'account-details',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //account list
            .addCase(accountList.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.success = false;
            })
            .addCase(accountList.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.accountManagementInfo = payload;
                state.success = true;
            })
            .addCase(accountList.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
                state.success = false;
            })
            //add account
            .addCase(addAccount.pending, (state) => {
                state.loading1 = true;
                state.error1 = null;
                state.success1 = false;
            })
            .addCase(addAccount.fulfilled, (state, { payload }) => {
                state.loading1 = false;
                state.addAccInfo = payload;
                state.success1 = true;
            })
            .addCase(addAccount.rejected, (state, { payload }) => {
                state.loading1 = false;
                state.error1 = payload;
                state.success1 = false;
            })
            //get account user
            .addCase(accountUser.pending, (state) => {
                state.loading2 = true;
                state.error = null;
                state.success2 = true;
            })
            .addCase(accountUser.fulfilled, (state, { payload }) => {
                state.loading2 = false;
                state.accUserInfo = payload;
                state.success2 = false;
            })
            .addCase(accountUser.rejected, (state, { payload }) => {
                state.loading2 = false;
                state.error2 = payload;
                state.success2 = false;
            })
            //add account data
            .addCase(getAccountData.pending, (state) => {
                state.loading3 = true;
                state.error3 = null;
                state.success3 = false;
            })
            .addCase(getAccountData.fulfilled, (state, { payload }) => {
                state.loading3 = false;
                state.accUpdateInfo = payload;
                state.success3 = true;
            })
            .addCase(getAccountData.rejected, (state, { payload }) => {
                state.loading3 = false;
                state.error3 = payload;
                state.success3 = false;
            })
            //add user
            .addCase(addUser.pending, (state) => {
                state.loading4 = true;
                state.error4 = null;
                state.success4 = false;
            })
            .addCase(addUser.fulfilled, (state, { payload }) => {
                state.loading4 = false;
                state.addUserInfo = payload;
                state.success4 = true;
            })
            .addCase(addUser.rejected, (state, { payload }) => {
                state.loading4 = false;
                state.error4 = payload;
                state.success4 = false;
            })
            .addCase(getUserGroup.pending, (state) => {
                state.loading5 = true;
                state.error5 = null;
                state.success5 = false;
            })
            .addCase(getUserGroup.fulfilled, (state, { payload }) => {
                state.loading5 = false;
                state.getUserGrp = payload;
                state.success5 = true;
            })
            .addCase(getUserGroup.rejected, (state, { payload }) => {
                state.loading5 = false;
                state.error5 = payload;
                state.success5 = false;
            })
            // get user info
            .addCase(getUserData.pending, (state) => {
                state.loading6 = true;
                state.error6 = null;
                state.success6 = false;
            })
            .addCase(getUserData.fulfilled, (state, { payload }) => {
                state.loading6 = false;
                state.getUserInfo = payload;
                state.success6 = true;
            })
            .addCase(getUserData.rejected, (state, { payload }) => {
                state.loading6 = false;
                state.error6 = payload;
                state.success6 = false;
            });
    },
});
export default accountManagementSlice.reducer;
