import { createSlice } from '@reduxjs/toolkit'
import { approveRequest, releaseReq, resultDownload, getrequestinfo, addPntDetail, getTopoInfo, reassessment,edfAnalysis } from 'store/actions/pipeline-request/wizard-api/approveRequestActions.js';

const initialState = {
  loading: false,
  appreq: null,
  error: null,
  success: false,
  //release req
  loading1: false,
  releaseInfo: null,
  error1: null,
  success1: null,
  //resultDownload
  loading2: false,
  resdownInfo: null,
  error2: null,
  success2: null,
  //getrequestinfo
  loading3: false,
  reqInfo: null,
  error3: null,
  success3: null,
  //pntdetail
  loading4: false,
  pntInfo: null,
  error4: null,
  success4: null,
  //topoinfo
  loading5: false,
  topoInfo: null,
  error5: null,
  success5: null,
  //reassessment
  loading6: false,
  reAss: null,
  error6: null,
  success6: null,
  //edf analysis
  loading7: false,
  edfals: null,
  error7: null,
  success7: null,
}

const approveRequestSlice = createSlice({
  name: 'approveRequest',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(approveRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(approveRequest.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.appreq = payload;
        state.success = true;
      })
      .addCase(approveRequest.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
        state.success = false;
      })
      //relased req
      .addCase(releaseReq.pending, (state) => {
        state.loading1 = true;
        state.error1 = null;
        state.success1 = false;
      })
      .addCase(releaseReq.fulfilled, (state, { payload }) => {
        state.loading1 = false;
        state.releaseInfo = payload;
        state.success1 = true;
      })
      .addCase(releaseReq.rejected, (state, { payload }) => {
        state.loading1 = false;
        state.error1 = payload;
        state.success1 = false;
      })

      //resultDownload
      .addCase(resultDownload.pending, (state) => {
        state.loading2 = true;
        state.error2 = null;
        state.success2 = false;
      })
      .addCase(resultDownload.fulfilled, (state, { payload }) => {
        state.loading2 = false;
        state.resdownInfo = payload;
        state.success2 = true;
      })
      .addCase(resultDownload.rejected, (state, { payload }) => {
        state.loading2 = false;
        state.error2 = payload;
        state.success2 = false;
      })

      //getrequestinfo
      .addCase(getrequestinfo.pending, (state) => {
        state.loading3 = true;
        state.error3 = null;
        state.success3 = false;
      })
      .addCase(getrequestinfo.fulfilled, (state, { payload }) => {
        state.loading3 = false;
        state.reqInfo = payload;
        state.success3 = true;
      })
      .addCase(getrequestinfo.rejected, (state, { payload }) => {
        state.loading3 = false;
        state.error3 = payload;
        state.success3 = false;
      })
      //add pnt detail template
      .addCase(addPntDetail.pending, (state) => {
        state.loading4 = true;
        state.error4 = null;
        state.success4 = false;
      })
      .addCase(addPntDetail.fulfilled, (state, { payload }) => {
        state.loading4 = false;
        state.pntInfo = payload;
        state.success4 = true;
      })
      .addCase(addPntDetail.rejected, (state, { payload }) => {
        state.loading4 = false;
        state.error4 = payload;
        state.success4 = false;
      })
      //add pnt detail template
      .addCase(getTopoInfo.pending, (state) => {
        state.loading5 = true;
        state.error5 = null;
        state.success5 = false;
      })
      .addCase(getTopoInfo.fulfilled, (state, { payload }) => {
        state.loading5 = false;
        state.topoInfo = payload;
        state.success5 = true;
      })
      .addCase(getTopoInfo.rejected, (state, { payload }) => {
        state.loading5 = false;
        state.error5 = payload;
        state.success5 = false;
      })
       //reassessment
       .addCase(reassessment.pending, (state) => {
        state.loading6 = true;
        state.error6 = null;
        state.success6 = false;
      })
      .addCase(reassessment.fulfilled, (state, { payload }) => {
        state.loading6 = false;
        state.reAss = payload;
        state.success6 = true;
      })
      .addCase(reassessment.rejected, (state, { payload }) => {
        state.loading6 = false;
        state.error6 = payload;
        state.success6 = false;
      })
      //edf analysis 
      .addCase(edfAnalysis.pending, (state) => {
        state.loading7 = true;
        state.error7 = null;
        state.success7 = false;
      })
      .addCase(edfAnalysis.fulfilled, (state, { payload }) => {
        state.loading7 = false;
        state.edfals = payload;
        state.success7 = true;
      })
      .addCase(edfAnalysis.rejected, (state, { payload }) => {
        state.loading7 = false;
        state.error7 = payload;
        state.success7 = false;
      })
  },
});

export default approveRequestSlice.reducer
