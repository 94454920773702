import React, { useState, useEffect } from 'react';
import twoStepImg from 'assets/images/authenticator1.png';
import { Steps, Tooltip } from 'components/elements/AntComponent';
import { Button, Input } from 'components/elements/FormComponent';
import QrCodeImg from 'assets/images/report-icons/qrCode.png';
import { message, useDispatch, useSelector } from 'components/elements/CommonComponent';
import { mfaCodeVerify, mfaCodeReset, userLogin } from 'store/actions/authActions';
import { useNavigate, Link } from 'react-router-dom';

export default function MfaStepScreen() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState('1');
    const { userInfo, verifyMfa, loading7, error7, success7, loading8, success8, error8 } = useSelector((state) => state.auth);
    const [showSuccessmsg, setShowSuccessmsg] = useState(false);
    const successmsg = showSuccessmsg ? success7 : null;
    const [showErrormsg, setShowErrormsg] = useState(false);
    const errormsg = showErrormsg ? error7 : null;
    const [showSuccessmsg1, setShowSuccessmsg1] = useState(false);
    const successmsg1 = showSuccessmsg1 ? success8 : null;
    const [showErrormsg1, setShowErrormsg1] = useState(false);
    const errormsg1 = showErrormsg1 ? error8 : null;
    const [code, setCode] = useState();

    const handleChange = (event) => {
        setCode(event.target.value);
    };

    const verifyCode = () => {
        let inputJson = {
            app_code: code,
        };
        dispatch(mfaCodeVerify(inputJson));
        setShowSuccessmsg(true);
        setShowErrormsg(true);
    };

    const resetCode = () => {
        let inputJson = {
            userid: sessionStorage.getItem('userid'),
        };
        dispatch(mfaCodeReset(inputJson));
        setShowSuccessmsg1(true);
        setShowErrormsg1(true);
    };

    const UpdateLogin = () => {
        let inputJson = {
            username: userInfo?.data?.username?.trim(),
            password: userInfo?.data?.password?.trim(),
        };
        dispatch(userLogin(inputJson));
    };

    useEffect(() => {
        if (successmsg) {
            message.success('Logged in successfully');
            navigate('/dashboard');
            setShowSuccessmsg(false);
        }
        if (errormsg) {
            message.error('Code verification failed. Please ensure the details are correct');
            setShowErrormsg(false);
        }
    }, [successmsg, errormsg]);

    useEffect(() => {
        if (successmsg1) {
            message.success('Qr code reset successfully');
            setShowSuccessmsg1(false);
            UpdateLogin();
        }
        if (errormsg1) {
            message.error('Qr code reset failed');
            setShowErrormsg1(false);
        }
    }, [successmsg1, errormsg1]);

    return (
        <div className="container-fluid h-100">
            <div className="d-flex my-auto mt-5 pt-5">
                <div>
                    <img src={twoStepImg} alt="step icon" className="authenticator-img" height={300} />
                </div>
                <div className="col-md-4">
                    <h2>Multi Factor Authentication</h2>
                    <div className="bg-white p-3 rounded ">
                        <Steps
                            direction="vertical"
                            current={currentStep}
                            onChange={(e) => setCurrentStep(e)}
                            items={[
                                {
                                    title: 'Start by getting the app',
                                    description: 'On your phone, install the Microsoft Authenticator app.',
                                    status: 'wait',
                                },
                                userInfo?.data?.qr_code !== null
                                    ? {
                                          className: 'custom-step-no-hover',
                                          title: 'Scan QR Code',
                                          description: (
                                              <div>
                                                  Use the Microsoft Authenticator app to scan the QR code. This will connect the Microsoft Authenticator app with your account.
                                                  <div>
                                                      <img src={userInfo?.data?.qr_code} className="qrcode-scanner" height="100px" />
                                                  </div>
                                              </div>
                                          ),
                                          status: 'wait',
                                      }
                                    : {
                                          title: (
                                              <div className="d-flex ">
                                                  <span>Account Connected</span>
                                                  <Tooltip title="Reset Qr code" className="mt-0">
                                                      <Button type="link" loading={loading8} onClick={() => resetCode()}>
                                                          Reset
                                                      </Button>
                                                  </Tooltip>
                                              </div>
                                          ),
                                          description: 'The Microsoft Authenticator app is successfully connected to your account. Please provide the OTP to complete the process.',
                                          status: 'wait',
                                      },
                                {
                                    title: 'Enter Code manually',
                                    description: (
                                        <div>
                                            Enter temporary OTP from your authenticator app.
                                            <div>
                                                <Input
                                                    className="bg-light"
                                                    onChange={handleChange}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            verifyCode();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ),
                                    status: 'wait',
                                },
                            ]}
                        />
                        <div>
                            <Button type="primary" className="text-end ms-auto" loading={loading7} onClick={verifyCode}>
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
